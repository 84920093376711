/* Google fonts: https://fonts.google.com/ */
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.app {
  background-color: #282c34;
  font-family: 'Josefin Sans', cursive;
  text-align: center;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.fade-in {
  border-color: aqua;
  border-width: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }  
  .fade-in {
    animation: fadeInAnimation 3s linear;
  }
}

.app-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

